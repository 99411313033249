<template>
  <div>
        <kendo-grid
            ref="grid" 
            :data-source="dataSource"
            :filterable="filterableConfig"
            :sortable="true"
            :pageable="pageableConfig"
            :columns="columns"
            :resizable="true"
            >
        </kendo-grid>
  </div>
</template>


<script>
import { Grid, GridColumn } from '@progress/kendo-grid-vue-wrapper';
import { globalfunc } from '../../../../shared/GlobalFunction';
import paymentInvoiceServices from '../Script/PaymentInvoiceServices';
import { apiBaseUrl } from '../../../../infrastructure/constant/connection'
import { kendo_grid } from '../../../../infrastructure/constant/variable';

export default {
    name: 'PaymentInvoiceGrid',
    components: {
        'kendo-grid' : Grid,
        'kendo-grid-column': GridColumn,
    },
    props: ['editClick', 'deleteClick', 'statusClick'],
    data: function () {
        return  {
            dataSource:  new kendo.data.DataSource ({
                    page: 1,
                    pageSize: kendo_grid.default_grid_pagesize,
                    serverPaging: true,
                    serverFiltering: true,
                    serverSorting: true,
                    transport: {
                        read: {
                            contentType: "application/json",
                            url: apiBaseUrl,
                            type: "POST",
                            data: function(e) {
                                var ignoreField = ["invoice_numbers"];
                                var swapField = [
                                    {field: "invoice_numbers", new_field: "string_agg(invoice_number,',')"},
                                    {field: "customer_name", new_field: "\"Customer\".\"contact_name\""},
                                    {field: "notes", new_field: "\"transaction_invoice_payment\".\"notes\""},
                                    {field: "total", new_field: "\"transaction_invoice_payment\".\"total\""},
                                ];
                                
                                const paging = {
                                    Page : {Limit: e.pageSize,Page: e.page},
                                    Filter : globalfunc.filterQueryBuilder(e.filter, swapField, ignoreField),
                                    OrderBy : globalfunc.sortingQueryBuilder(e.sort),
                                    Having : globalfunc.havingQueryBuilder(e.filter, swapField, ignoreField),
                                }
                                
                                const variables = {
                                    paging : paging
                                }
                                return { query: paymentInvoiceServices.readPaymentInvoiceQuery(),
                                         variables: variables};
                            },
                            beforeSend: function (req) {
                                req.setRequestHeader('Authorization', window.$cookies.get('Token'));
                                req.setRequestHeader('Path', window.location.pathname);
                            }
                        },
                        parameterMap: function(options, operation) {
                            return  kendo.stringify({
                                query: options.query,
                                variables: options.variables
                            });
                        },
                        cache: false,
                    },
                    schema: {
                        data: function (response) {
                            if(response.data.GetTransactionInvoicePayment.transaction_invoice_payment == null){
                                return [];
                            }else{
                                return response.data.GetTransactionInvoicePayment.transaction_invoice_payment;
                            }
                        },
                        total: function (response) {
                            if(response.data.GetTransactionInvoicePayment.transaction_invoice_payment == null){
                                return 0;
                            }else{
                                return response.data.GetTransactionInvoicePayment.total;
                            }
                        },
                        model: {
                            fields: {
                                total: {type: "number" },
                                inv_payment_date: {type: "date"},
                                inv_payment_type: {type: "dropdown"},
                            }
                        }
                    },
                    sort: { field: "inv_payment_date", dir: "desc" }
                }),
            columns:  [
                { title: "Action", width: 200, attributes: { style: "text-align:center;" }, headerAttributes:{ style: "text-align:center; font-weight:bold;" }, template:this.columnButton},
                { field: "status", title: "Status", width: 200, headerAttributes:{ style: "text-align:center; font-weight:bold;" }, attributes: { "class": "k-text-center" } },
                { field: "inv_payment_date", title: "Tanggal", width: 200, headerAttributes:{ style: "text-align:center; font-weight:bold;" }, attributes: { "class": "k-text-center" },
                    template: "#= kendo.toString(kendo.parseDate(inv_payment_date, 'yyyy-MM-dd'), 'yyyy-MM-dd') #" },
                { field: "inv_payment_number", title: "No. Bukti", width: 200, headerAttributes:{ style: "text-align:center; font-weight:bold;" } },
                { field: "invoice_numbers", title: "No. Invoice", width: 200, headerAttributes:{ style: "text-align:center; font-weight:bold;" } },
                { field: "customer_name", title: "Customer", width: 200, headerAttributes:{ style: "text-align:center; font-weight:bold;" } },
                { field: "notes", title: "Catatan", width: 200, headerAttributes:{ style: "text-align:center; font-weight:bold;" } },
                { field: "inv_payment_type", title: "Tipe Invoice Payment", width: 200, headerAttributes:{ style: "text-align:center; font-weight:bold;" },
                    filterable: { ui: this.invoicePaymentTypeFilter },
                    template: "#= inv_payment_type == 1 ? 'Payment' : 'Deposit' #" },
                { field: "total", title: "Total", width: 200, headerAttributes:{ style: "text-align:center; font-weight:bold;" }, attributes: { "class": "k-text-right" },
                    template: "#= kendo.toString((total), 'n2') #" 
                },
                { field: "payment_method", title: "Payment Method", width: 200, headerAttributes:{ style: "text-align:center; font-weight:bold;" } },
                { field: "bank", title: "Bank", width: 200, headerAttributes:{ style: "text-align:center; font-weight:bold;" } },
                { field: "account_name", title: "Nama Akun", width: 200, headerAttributes:{ style: "text-align:center; font-weight:bold;" } },
            ],
            filterableConfig:  {
                extra: true,
                operators: kendo_grid.default_operator
            },
            pageableConfig: kendo_grid.default_pageable_config,
            invoicePaymentTypeFilterData: [
                {value: 1, label: 'Payment'},
                {value: 2, label: 'Deposit'}
            ],
        };
    },
    mounted: async function() {
        var grid = this.$refs.grid.kendoWidget();
        var deleteClick = this.$props.deleteClick;
        var editClick = this.$props.editClick;
        var statusClick = this.$props.statusClick;
       
        var GridElement = grid.element;

        GridElement.on("click", "#DeleteButton", function (e){
            e.preventDefault();
            var dataItem = grid.dataItem(e.target.closest("tr"));

            deleteClick(dataItem.inv_payment_id);
        })

        GridElement.on("click", "#EditButton", function (e){
            e.preventDefault();
            var dataItem = grid.dataItem(e.target.closest("tr"));

            editClick(dataItem.inv_payment_id, false);
        })

        GridElement.on("click", "#ViewButton", function (e){
            e.preventDefault();
            var dataItem = grid.dataItem(e.target.closest("tr"));

            editClick(dataItem.inv_payment_id, true);
        })
        
        ////Status Form
        GridElement.on("click", "#StatusButton", function (e){
            e.preventDefault();
            var dataItem = grid.dataItem(e.target.closest("tr"));

            const invocieData = {
                inv_payment_id : dataItem.inv_payment_id,
                inv_payment_no : dataItem.inv_payment_no,
                status : dataItem.status
            }
            
            statusClick(invocieData);
        })
    },
    methods: {
        invoicePaymentTypeFilter: function(e) {
            e.kendoDropDownList({
                autoBind: false,
                dataSource: this.invoicePaymentTypeFilterData,
                dataTextField: "label",
                dataValueField: "value",
                optionLabel: "Pilih Tipe Invoice Payment"
            });
        },
        columnButton(){
            return this.$globalfunc.gridActionWithStatusChangesButton("Payment Invoice")
        },
        changeStatus(status){
            this.dataSource=  new kendo.data.DataSource ({
                page: 1,
                pageSize: kendo_grid.default_grid_pagesize,
                serverPaging: true,
                serverFiltering: true,
                serverSorting: true,
                transport: {
                    read: {
                        contentType: "application/json",
                        url: apiBaseUrl,
                        type: "POST",
                        data: function(e) {
                            var ignoreField = ["invoice_numbers"];
                            var swapField = [
                                {field: "invoice_numbers", new_field: "string_agg(invoice_number,',')"},
                                {field: "customer_name", new_field: "\"Customer\".\"contact_name\""},
                                {field: "notes", new_field: "\"transaction_invoice_payment\".\"notes\""},
                                {field: "total", new_field: "\"transaction_invoice_payment\".\"total\""},
                            ];
                            
                            const paging = {
                                Page : {Limit: e.pageSize,Page: e.page},
                                Filter : globalfunc.filterQueryBuilder(e.filter, swapField, ignoreField),
                                OrderBy : globalfunc.sortingQueryBuilder(e.sort),
                                Having : globalfunc.havingQueryBuilder(e.filter, swapField, ignoreField),
                            }

                            const variables = {
                                paging : paging,
                                status : status
                            }
                            return { query: paymentInvoiceServices.readPaymentInvoiceQuery(),
                                        variables: variables};
                        },
                        beforeSend: function (req) {
                            req.setRequestHeader('Authorization', window.$cookies.get('Token'));
                            req.setRequestHeader('Path', window.location.pathname);
                        }
                    },
                    parameterMap: function(options, operation) {
                        return  kendo.stringify({
                            query: options.query,
                            variables: options.variables
                        });
                    },
                    cache: false,
                },
                schema: {
                    data: function (response) {
                        if(response.data.GetTransactionInvoicePayment.transaction_invoice_payment == null){
                            return [];
                        }else{
                            return response.data.GetTransactionInvoicePayment.transaction_invoice_payment;
                        }
                    },
                    total: function (response) {
                        if(response.data.GetTransactionInvoicePayment.transaction_invoice_payment == null){
                            return 0;
                        }else{
                            return response.data.GetTransactionInvoicePayment.total;
                        }
                    },
                    model: {
                        fields: {
                            total: {type: "number" },
                            inv_payment_date: {type: "date"},
                            inv_payment_type: {type: "dropdown"},
                        }
                    }
                },
                sort: { field: "inv_payment_date", dir: "desc" }
            });
        },
    }
}
</script>