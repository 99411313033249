<template>
    <div>
        <CCard class="w-100">
            <CCardHeader>
                <h2>Payment Invoice</h2>
            </CCardHeader>
            <CCardBody>
                <span v-html="statusButton"></span>

                <div class="col-xs-6 text-light pb-2">
                    <button id="add" class="btn btn-success"                   
                    @click="addClick()">
                        <i class="fa fa-plus"></i> Add
                    </button>
                </div>
                
                <payment-invoice-grid ref="grid" :key="gridReload" :editClick="editClick" :deleteClick="deleteClick" :statusClick="statusClick" />
                <payment-invoice-create-form  ref="PaymentInvoiceCreateForm" :reload="reload"/>
                <payment-invoice-status-form  ref="PaymentInvoiceStatusForm" :reload="reload"/>
            </CCardBody>
        </CCard>
    </div>
</template>

<script>
import { globalfunc } from '../../../shared/GlobalFunction';
import paymentInvoiceCreateForm from './Component/PaymentInvoiceCreateForm.vue';
import paymentInvoiceGrid from './Grid/PaymentInvoiceGrid.vue';
import paymentInvoiceServices from './Script/PaymentInvoiceServices';
import paymentInvoiceStatusForm from '../PaymentInvoice/Component/PaymentInvoiceStatusForm.vue'
import { response } from '../../../infrastructure/constant/response';
import moment from 'moment';
import { sweet_alert } from '../../../infrastructure/constant/variable';

export default {
    name: 'PaymentInvoice',
    components: {
        'payment-invoice-create-form': paymentInvoiceCreateForm,
        'payment-invoice-grid': paymentInvoiceGrid,
        'payment-invoice-status-form': paymentInvoiceStatusForm,
    },
    async mounted () {
        var permission = this.$globalfunc.permissionModule('Payment Invoice');
        document.getElementById("add").disabled = permission.cperms ? false : true;
        
        this.statusFilter();
    },
    data(){
        return{
            gridReload: 0,
            statusButton: '',
        }
    },
    methods: {
        addClick(){
            this.$refs.PaymentInvoiceCreateForm.addClick();
        },
        editClick(id, view){
            // this.$router.push({ name: 'Payment Invoice Form', params: { formtype:'Edit', id:id, view:view } });
            const routeData = this.$router.resolve({ name: 'Payment Invoice Form', params: { formtype:'Edit', id:id, view:view } });
            window.open(routeData.href, '_blank');
        },
        async deleteClick(id){
            var data = await paymentInvoiceServices.getPayableInvoiceDetail(id);

            //item GridData
            var gridItem = paymentInvoiceServices.paymentGridPaymentInvoiceEdit(data.invoice_payment_detail);
            var invoicePaymentDetail = this.$globalfunc.objectToArrayConverter(gridItem, "Transaction-PaymentInvoice-InvoicePaymentDetail");

            const paymentInvoiceData = {
                inv_payment_type: data.inv_payment_type,
                status: "Delete",
                customer_id: data.customer_id,
                inv_payment_date: moment(data.inv_payment_date).format("YYYY-MM-DD"),
                inv_payment_number: null,
                payment_method: data.payment_method,
                bank: data.bank,
                branch: data.branch,
                account_number: data.account_number,
                account_name: data.account_name,
                notes: data.notes,
                round_up: parseFloat(data.round_up),
                delivery_fee: parseFloat(data.delivery_fee),
                admin_fee: parseFloat(data.admin_fee),
                other_fee: parseFloat(data.other_fee),
                transaction_invoice_payment_detail: invoicePaymentDetail
            }

            const variables = {
                id : parseInt(id),
                data : paymentInvoiceData
            }

            this.$swal(sweet_alert.delete).then((result) => {
                if (result.isConfirmed == true) {
                    paymentInvoiceServices.editQuery(variables).then(res => {
                        this.reload();
                        this.$swal("Info", response.successDelete, "success");                 
                    }).catch(error => {
                        var errorMessage = this.$globalfunc.errorExtractor(error)
                        this.$swal("Error", errorMessage, "error");
                    })
                }
            })
        },
        statusClick(data) {
            this.$refs.PaymentInvoiceStatusForm.statusClick(data);
        },
        reload(){
            this.gridReload++;
            this.statusFilter();
        },
        async statusFilter() {
            // var countStatus = await globalfunc.countTotal(window.location.pathname);
            // this.statusButton = await this.$globalfunc.dynamicStatusButton('ddl_status_invoice_payment', countStatus);
            this.statusButton = await this.$globalfunc.dynamicStatusButton();
            
            var vue = this;
            this.$nextTick(function () {
                $("#btnAll").on('click', function() {
                    vue.reload();
                });
                $("#btnClear").on('click', function() {
                    vue.$refs.grid.changeStatus('Clear');
                });
                $("#btnUnclear").on('click', function() {
                    vue.$refs.grid.changeStatus('Unclear');
                });
            })
        },
    }
}
</script>

<style scoped>
</style>