<template>
    <div>
        <CCard class="w-100">
            <CCardHeader>
                <h2>{{FormType}} Payment Invoice : {{PaymentInvoiceCode}}</h2>
            </CCardHeader>
            <CCardBody>
                <div class="px-3">
                    <CRow>
                        <div class="col-sm-label">
                            <label class="form-label font-weight-bold">Nomor Bukti</label>
                            <label class="form-label font-weight-bold" style="float:right">:&nbsp;</label>
                        </div>
                        <div class="col-sm-content">
                            <CInput id="PaymentInvoiceCode" v-model="PaymentInvoiceCode" class="font-weight-bold"/>
                            <label id="errorPaymentInvoiceCode" class="form-error pb-2" style="display: none; color: red;"></label>
                        </div> 
                        <div class="col-sm-label">
                            <label class="form-label font-weight-bold">Customer</label>
                            <label class="form-label font-weight-bold" style="float:right">:&nbsp;</label>
                        </div>
                        <div class="col-sm-content">
                            <CInput id="Customer" v-model="Customer" class="font-weight-bold" readonly/>
                            <label id="errorCustomer" class="form-error pb-2" style="display: none; color: red;"></label>
                        </div> 
                        <div class="col-sm-label">
                            <label class="form-label font-weight-bold required">Tanggal</label>
                            <label class="form-label font-weight-bold" style="float:right">:&nbsp;</label>
                        </div>
                        <div class="col-sm-content">
                            <date-picker
                                id="PaymentInvoiceDate"
                                v-model="PaymentInvoiceDate"
                                format="DD MMM YYYY"
                                type="date"
                                placeholder="Select date"
                                value-type="YYYY-MM-DD"
                                style="width:100%"
                            ></date-picker>
                            <label id="errorPaymentInvoiceDate" class="form-error pb-2" style="display: none; color: red;"></label>
                        </div>
                    </CRow>
                    <CRow>
                        <div class="col-sm-label">
                            <label class="form-label font-weight-bold">Payment Method</label>
                            <label class="form-label font-weight-bold" style="float:right">:&nbsp;</label>
                        </div>
                        <div class="col-sm-content">
                            <CSelect id="PaymentMethod" class="font-weight-bold" :options="PaymentMethodData" :value.sync="PaymentMethod" />
                            <label id="errorPaymentMethod" class="form-error" style="display: none; color: red;"></label>
                        </div> 
                        <div class="col-sm-label">
                            <label class="form-label font-weight-bold required">Via Bank</label>
                            <label class="form-label font-weight-bold" style="float:right">:&nbsp;</label>
                        </div>
                        <div class="col-sm-content">
                            <v-select id="Bank" class="pb-3" :options="BankData" v-model="Bank"/>
                            <label id="errorBank" class="form-error" style="display: none; color: red;"></label>
                        </div> 
                        <div class="col-sm-label">
                            <label class="form-label font-weight-bold">Cabang</label>
                            <label class="form-label font-weight-bold" style="float:right">:&nbsp;</label>
                        </div>
                        <div class="col-sm-content">
                            <CInput id="Branch" v-model="Branch" class="font-weight-bold"/>
                            <label id="errorBranch" class="form-error pb-2" style="display: none; color: red;"></label>
                        </div> 
                    </CRow>
                    <CRow>
                        <div class="col-sm-label">
                            <label class="form-label font-weight-bold">No. Account</label>
                            <label class="form-label font-weight-bold" style="float:right">:&nbsp;</label>
                        </div>
                        <div class="col-sm-content">
                            <CInput id="AccountNumber" v-model="AccountNumber" class="font-weight-bold"/>
                            <label id="errorAccountNumber" class="form-error pb-2" style="display: none; color: red;"></label>
                        </div> 
                        <div class="col-sm-label">
                            <label class="form-label font-weight-bold">Nama Account</label>
                            <label class="form-label font-weight-bold" style="float:right">:&nbsp;</label>
                        </div>
                        <div class="col-sm-content">
                            <CInput id="AccountName" v-model="AccountName" class="font-weight-bold"/>
                            <label id="errorAccountName" class="form-error pb-2" style="display: none; color: red;"></label>
                        </div>
                        <div class="col-sm-label">
                            <label class="form-label font-weight-bold">Status</label>
                            <label class="form-label font-weight-bold" style="float:right">:&nbsp;</label>
                        </div>
                        <div class="col-sm-content">
                            <CSelect id="Status" class="font-weight-bold" :options="StatusData" :value.sync="Status" />
                            <label id="errorStatus" class="form-error" style="display: none; color: red;"></label>
                        </div> 
                    </CRow>
                    <CRow>
                        <div class="col-sm-label">
                            <label class="form-label font-weight-bold">Tipe Invoice</label>
                            <label class="form-label font-weight-bold" style="float:right">:&nbsp;</label>
                        </div>
                        <div class="col-sm-content">
                            <CSelect id="InvoicePaymentType" class="font-weight-bold" :options="InvoicePaymentTypeData" :value.sync="InvoicePaymentType" disabled/>
                            <label id="errorInvoicePaymentType" class="form-error" style="display: none; color: red;"></label>
                        </div> 
                        <div class="col-sm-label">
                            <label class="form-label font-weight-bold">Catatan</label>
                            <label class="form-label font-weight-bold" style="float:right">:&nbsp;</label>
                        </div>
                        <div class="col-sm-content">
                            <textarea class="form-control" rows="2" v-model="Note"></textarea>
                            <label id="errorNote" class="form-error pb-2" style="display: none; color: red;"></label>
                        </div>
                        <div class="col">
                            <label style="float: right;"><h1>Rp. {{TotalPayment}}</h1></label>
                        </div>
                    </CRow>
                    <br>
                    <CRow class="px-3" >
                        <div class="p-3 border rounded" style="width: 100%;">
                            <label class="form-label font-weight-bold" style="margin-bottom: 0px"><h4>Detail</h4></label>
                            <hr>
                            <datasource ref="invoiceDataSource" :data="this.InvoiceGridData" :page-size="10" :schema-model-fields="this.InvoiceSchemaModel" :aggregate="this.InvoiceGridAggregate"/>

                            <kendo-grid ref="gridInvoice"
                                        :data-source-ref="'invoiceDataSource'"
                                        :pageable="true"
                                        :editable="EditableValue"
                                        :resizable="true"
                                        :filterable="filterableConfig"
                                        :sortable="true"
                                        :cellClose="invoiceCellClose"
                                        >
                                <kendo-grid-column  :field="'invoice_number'"
                                                    :title="'Nomor Invoice'"
                                                    :width="150"
                                                    :headerAttributes="{ style: 'text-align:center; font-weight:bold;' }"></kendo-grid-column>

                                <kendo-grid-column  :field="'total'"
                                                    :title="'Nilai Invoice'"
                                                    :format="'{0:N0}'"
                                                    :width="150"
                                                    :headerAttributes="{ style: 'text-align:center; font-weight:bold;' }"
                                                    :attributes="{style:'text-align:right'}"
                                                    :aggregates="['sum']"
                                                    :footerTemplate="` <div style='float: right'>Rp. #=  kendo.toString(sum, 'n') #</div>`"></kendo-grid-column>

                                <kendo-grid-column  :field="'paid'"
                                                    :title="'Terbayar'"
                                                    :format="'{0:N0}'"
                                                    :width="150"
                                                    :headerAttributes="{ style: 'text-align:center; font-weight:bold;' }"
                                                    :attributes="{style:'text-align:right'}"
                                                    :aggregates="['sum']"
                                                    :footerTemplate="` <div style='float: right'>Rp. #=  kendo.toString(sum, 'n') #</div>`"></kendo-grid-column>

                                <kendo-grid-column  :field="'left'"
                                                    :title="'Jumlah Bayar'"
                                                    :format="'{0:N0}'"
                                                    :width="150"
                                                    :headerAttributes="{ style: 'text-align:center; font-weight:bold;' }"
                                                    :attributes="{style:'text-align:right'}"
                                                    :aggregates="['sum']"
                                                    :footerTemplate="` <div style='float: right'>Rp. #=  kendo.toString(sum, 'n') #</div>`"></kendo-grid-column>

                                <kendo-grid-column  :field="'remaining'"
                                                    :title="'Sisa'"
                                                    :format="'{0:N0}'"
                                                    :width="150"
                                                    :headerAttributes="{ style: 'text-align:center; font-weight:bold;' }"
                                                    :attributes="{style:'text-align:right'}"
                                                    :aggregates="['sum']"
                                                    :footerTemplate="` <div style='float: right'>Rp. #=  kendo.toString(sum, 'n') #</div>`"></kendo-grid-column>

                                <kendo-grid-column  :field="'tax_invoice'"
                                                    :title="'No. Faktur PPN'"
                                                    :width="150"
                                                    :headerAttributes="{ style: 'text-align:center; font-weight:bold;' }"></kendo-grid-column>

                                <kendo-grid-column  :field="'notes'"
                                                    :title="'Catatan'"
                                                    :width="150"
                                                    :headerAttributes="{ style: 'text-align:center; font-weight:bold;' }"></kendo-grid-column>
                            </kendo-grid>
                        </div>
                    </CRow>
                     <hr>
                    <CRow class="pb-3">
                        <div class="col-sm-label">
                        </div>
                        <div class="col-sm-content">
                        </div> 
                        <div class="col-sm-label">
                        </div>
                        <div class="col-sm-content">
                        </div> 
                        <div class="col-sm-label">
                            <label class="form-label font-weight-bold">Pembulatan</label>
                            <label class="form-label font-weight-bold" style="float:right">:&nbsp;</label>
                        </div>
                        <div class="col-sm-content">
                            <input type="number" id="RoundUp" v-model="RoundUp" class="font-weight-bold form-control input-number" @change="countTotalPayment"/>
                            <label id="errorRoundUp" class="form-error pb-2" style="display: none; color: red;"></label>
                        </div> 
                    </CRow>
                    <CRow class="pb-3">
                        <div class="col-sm-label">
                        </div>
                        <div class="col-sm-content">
                        </div> 
                        <div class="col-sm-label">
                        </div>
                        <div class="col-sm-content">
                        </div> 
                        <div class="col-sm-label">
                            <label class="form-label font-weight-bold">Ongkos Kirim</label>
                            <label class="form-label font-weight-bold" style="float:right">:&nbsp;</label>
                        </div>
                        <div class="col-sm-content">
                            <input type="number" id="DeliveryFee" v-model="DeliveryFee" class="font-weight-bold form-control input-number" @change="countTotalPayment"/>
                            <label id="errorDeliveryFee" class="form-error pb-2" style="display: none; color: red;"></label>
                        </div> 
                    </CRow>
                    <CRow class="pb-3">
                        <div class="col-sm-label">
                        </div>
                        <div class="col-sm-content">
                        </div> 
                        <div class="col-sm-label">
                        </div>
                        <div class="col-sm-content">
                        </div> 
                        <div class="col-sm-label">
                            <label class="form-label font-weight-bold">Biaya Admin</label>
                            <label class="form-label font-weight-bold" style="float:right">:&nbsp;</label>
                        </div>
                        <div class="col-sm-content">
                            <input type="number" id="AdminFee" v-model="AdminFee" class="font-weight-bold form-control input-number" @change="countTotalPayment"/>
                            <label id="errorAdminFee" class="form-error pb-2" style="display: none; color: red;"></label>
                        </div> 
                    </CRow>
                    <CRow class="pb-3">
                        <div class="col-sm-label">
                        </div>
                        <div class="col-sm-content">
                        </div> 
                        <div class="col-sm-label">
                        </div>
                        <div class="col-sm-content">
                        </div> 
                        <div class="col-sm-label">
                            <label class="form-label font-weight-bold">Biaya Lain</label>
                            <label class="form-label font-weight-bold" style="float:right">:&nbsp;</label>
                        </div>
                        <div class="col-sm-content">
                            <input type="number" id="OtherFee" v-model="OtherFee" class="font-weight-bold form-control input-number" @change="countTotalPayment"/>
                            <label id="errorOtherFee" class="form-error pb-2" style="display: none; color: red;"></label>
                        </div> 
                    </CRow>
                    <label id="errorPaymentInvoiceGeneral" class="form-error pb-2" style="display: none; color: red;"></label>
                    <CButton type="button" @click="saveClick()" class="save-button" id="saveButton" color="success" style="float: right;"> Save </CButton>
                    <label style="float: right;">&nbsp;&nbsp;</label>
                    <CButton type="button" color="secondary" style="float: right;" data-bs-dismiss="modal" aria-label="Close" @click="$router.push('/payment-invoice')"> Close </CButton>
                </div>
            </CCardBody>
        </CCard>
    </div>
</template>

<script>
import { globalfunc } from '../../../../shared/GlobalFunction.js';
import datePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';
import { Grid, GridColumn} from '@progress/kendo-grid-vue-wrapper';
import { DataSource } from '@progress/kendo-datasource-vue-wrapper';
import paymentInvoiceServices from '../Script/PaymentInvoiceServices';
import { response } from '../../../../infrastructure/constant/response';
import { baseUrl } from '../../../../infrastructure/constant/connection';
import moment from 'moment';
import { kendo_grid } from '../../../../infrastructure/constant/variable';

export default {
    name: 'PaymentFormPage',
    components: {
        'date-picker' : datePicker,
        'kendo-grid' : Grid,
        'kendo-grid-column': GridColumn,
        'datasource' : DataSource,
    },
    async mounted () {
        if(this.FormType == 'Add'){
            var data = await paymentInvoiceServices.getPayableInvoiceList(this.InvoiceId);
            
            this.PaymentInvoiceCode = 'Auto';
            this.CustomerId = data[0].customer_id;
            this.Customer = data[0].customer_name;

            var dateNow = moment(new Date()).format("YYYY-MM-DD");
            this.PaymentInvoiceDate = dateNow;

            this.PaymentMethod = 'Unknown';
            this.BankData = await paymentInvoiceServices.getBankQuery();
            this.Bank = '';
            this.Branch = '';
            this.AccountNumber = '';
            this.AccountName = '';
            this.StatusData = await globalfunc.globalDropdown('ddl_status_invoice_payment');
            this.Status = 'Unclear';
            this.Note = '';
            this.InvoicePaymentType = data[0].invoice_type;
            
            //Grid
            this.EditableValue = true;
            this.InvoiceGridDataOld = data;
            this.InvoiceGridData = paymentInvoiceServices.paymentGridPaymentInvoice(data, this.InvoiceGridDataOld);
            this.countTotalPayment();

            document.getElementById('PaymentInvoiceCode').readOnly = false;
            this.SaveType = 'Add';
        }
        else {
            var data = await paymentInvoiceServices.getPayableInvoiceDetail(this.Id);
            
            this.PaymentInvoiceCode = data.inv_payment_number;
            this.CustomerId = data.customer_id;
            this.Customer = data.customer_name;
            this.PaymentInvoiceDate = moment(data.inv_payment_date).format("YYYY-MM-DD");
            this.PaymentMethod = data.payment_method;
            this.BankData = await paymentInvoiceServices.getBankQuery();
            this.Bank = this.BankData.find(c => c.value == data.bank);
            this.Branch = data.branch;
            this.AccountNumber = data.account_number;
            this.AccountName = data.account_name;
            this.StatusData = await globalfunc.globalDropdown('ddl_status_invoice_payment');
            this.Status = data.status;
            this.Note = data.notes;
            this.InvoicePaymentType = data.inv_payment_type;

            this.RoundUp = data.round_up;
            this.DeliveryFee = data.delivery_fee;
            this.AdminFee = data.admin_fee;
            this.OtherFee = data.other_fee;

            this.EditableValue = false;

            //Grid
            this.InvoiceGridDataOld = data.invoice_payment_detail;
            this.InvoiceGridData = paymentInvoiceServices.paymentGridPaymentInvoiceEdit(data.invoice_payment_detail);
            this.countTotalPayment();

            if(this.View == 'true'){
                $(".save-button").hide();
            }

            document.getElementById('PaymentInvoiceCode').readOnly = true;
            this.SaveType = 'Edit';
        }
    },
    data(){
        return{
            FormType: this.$route.params.formtype,
            View: this.$route.params.view,
            Id: this.$route.params.id,
            Error : 0,

            //Detail
            InvoiceId: this.$route.params.id,
            PaymentInvoiceCode: '',
            CustomerId: '',
            Customer: '',
            PaymentInvoiceDate: '',
            PaymentMethodData: [ 
                {value: 'Unknown', label: 'Unknown'},
                {value: 'Cash', label: 'Cash'},
                {value: 'Debit Card', label: 'Debit Card'},
                {value: 'Credit Card', label: 'Credit Card'},
                {value: 'Bank Transfer', label: 'Bank Transfer'},
                {value: 'Auto Debet', label: 'Auto Debet'},
                {value: 'Auto Debet Credit Card', label: 'Auto Debet Credit Card'},
                {value: 'Cheque', label: 'Cheque'},
                {value: 'Giro', label: 'Giro'}
            ],
            PaymentMethod: '',
            BankData: [],
            Bank: '',
            Branch: '',
            AccountNumber: '',
            AccountName: '',
            StatusData: [],
            Status: '',
            Note: '',
            TotalPayment: '',
            TotalPaymentAmount: 0,
            RoundUp: 0,
            DeliveryFee: 0,
            AdminFee: 0,
            OtherFee: 0,
            InvoicePaymentTypeData: [   {value: 1, label: 'Payment'},
                                        {value: 2, label: 'Deposit'},],
            InvoicePaymentType: 0,
            
            SaveType: '',
            EditableValue: true,

            //grid
            InvoiceGridDataOld: [],
            InvoiceGridData: [],
            InvoiceSchemaModel: {
                invoice_number: { type: "string", editable: false },
                total: { type: "number", editable: false },
                paid: { type: "number", editable: false },
                left: { type: "number", editable: true, validation: { min:0 } },
                remaining: { type: "number", editable: false },
                notes: { type: "string", editable: false },
            },
            InvoiceGridAggregate:[
                { field: "total", aggregate: "sum" },
                { field: "paid", aggregate: "sum" },
                { field: "left", aggregate: "sum" },
                { field: "remaining", aggregate: "sum" }
            ],

            filterableConfig:  {
                extra: true,
                operators: kendo_grid.default_operator
            },
        }
    },
    methods: {
        async invoiceCellClose(e){
            var dataItem = e.sender.dataItem($(e.container).parent());
            var oldDataItem = this.InvoiceGridDataOld;
            
            if(this.InvoicePaymentType == 2 && dataItem.left != oldDataItem[0].total){
                this.$swal("Info", "Jumlah bayar invoice deposit tidak dapat diubah", "info");
                
                dataItem.left = oldDataItem[0].total;
                dataItem.paid = oldDataItem[0].paid;
                dataItem.remaining = 0;
            }else{
                var singleOldDataItem = oldDataItem.filter(item => item.invoice_id == dataItem.invoice_id);
                
                if (dataItem.left == null || dataItem.left < 1) {
                    this.$swal("Info", "Jumlah bayar tidak boleh kosong atau 0", "info");

                    dataItem.left = singleOldDataItem[0].left;
                    dataItem.paid = singleOldDataItem[0].paid;
                    dataItem.remaining = 0;
                }
                if (dataItem.left > singleOldDataItem[0].left) {
                    this.$swal("Info", "Jumlah bayar tidak boleh melebihi nilai invoice", "info");

                    dataItem.left = singleOldDataItem[0].left;
                    dataItem.paid = singleOldDataItem[0].total;
                    dataItem.remaining = 0;
                    
                    var gridItem = this.$refs.gridInvoice.kendoWidget().dataSource._data;
                    this.InvoiceGridData = paymentInvoiceServices.paymentGridPaymentInvoice(gridItem, oldDataItem);
                }
                else {
                    var gridItem = this.$refs.gridInvoice.kendoWidget().dataSource._data;
                    this.InvoiceGridData = paymentInvoiceServices.paymentGridPaymentInvoice(gridItem, oldDataItem);
                }

                this.countTotalPayment();
            }
        },
        countTotalPayment(){
            if(this.RoundUp == ''){
                this.RoundUp = 0;
            }
            if(this.DeliveryFee == ''){
                this.DeliveryFee = 0;
            }
            if(this.AdminFee == ''){
                this.AdminFee = 0;
            }
            if(this.OtherFee == ''){
                this.OtherFee = 0;
            }
            var gridData = this.InvoiceGridData;
            var RoundUp = parseFloat(this.RoundUp);
            var DeliveryFee = parseFloat(this.DeliveryFee);
            var AdminFee = parseFloat(this.AdminFee);
            var OtherFee = parseFloat(this.OtherFee);
            var totalGrid = 0;
            
            if(gridData.length > 0){
                for (let i = 0; i < gridData.length; i++) {
                    totalGrid = totalGrid + gridData[i].left;
                }
            }
            
            this.TotalPaymentAmount = totalGrid + RoundUp + DeliveryFee + AdminFee + OtherFee;
            this.TotalPayment = kendo.toString((totalGrid + RoundUp + DeliveryFee + AdminFee + OtherFee), 'n');
        },
        inputValidation(){
            this.Error = 0;
            $(".form-error").css("display", "none");

            //Validation
            var gridItemData = this.$refs.gridInvoice.kendoWidget().dataSource._data;
            if(gridItemData.length < 1){
                this.$swal("Error", "Detail payment invoice tidak boleh kosong", "error");
            }
            if(this.PaymentInvoiceDate == '' || this.PaymentInvoiceDate == null){
                this.errorShow('errorPaymentInvoiceDate');
            }
            if(this.Bank == '' || this.Bank == null){
                this.errorShow('errorBank');
            }

            if(this.RoundUp.toString() == '' || this.RoundUp == null) {
                this.errorShow('errorRoundUp');
            }
            else if(this.RoundUp < 0) {
                this.errorShow('errorRoundUp', response.fieldRequiredNotMinus);
            }
            
            if(this.DeliveryFee.toString() == '' || this.DeliveryFee == null) {
                this.errorShow('errorDeliveryFee');
            }
            else if(this.DeliveryFee < 0) {
                this.errorShow('errorDeliveryFee', response.fieldRequiredNotMinus);
            }
            
            if(this.AdminFee.toString() == '' || this.AdminFee == null) {
                this.errorShow('errorAdminFee');
            }
            else if(this.AdminFee < 0) {
                this.errorShow('errorAdminFee', response.fieldRequiredNotMinus);
            }
            
            if(this.OtherFee.toString() == '' || this.OtherFee == null) {
                this.errorShow('errorOtherFee');
            }
            else if(this.OtherFee < 0) {
                this.errorShow('errorOtherFee', response.fieldRequiredNotMinus);
            }
        },
        errorShow(errorCode, message){
            document.getElementById(errorCode).style.display = 'block';
            document.getElementById(errorCode).innerHTML = message == undefined ? response.fieldRequired : message;
            if(this.Error <= this.$globalvariable.max_error_focus){ document.getElementById(errorCode.replace("error", "")).focus(); }
            this.Error++;
        },
        async saveClick(){
            this.inputValidation();

            //Proses
            if(this.Error == 0){
                //item GridData
                var gridItemData = this.$refs.gridInvoice.kendoWidget().dataSource._data;
                var invoicePaymentDetail = this.$globalfunc.objectToArrayConverter(gridItemData, "Transaction-PaymentInvoice-InvoicePaymentDetail");

                const paymentInvoiceData = {
                    inv_payment_type: this.InvoicePaymentType,
                    inv_payment_number: this.PaymentInvoiceCode,
                    inv_payment_date: this.PaymentInvoiceDate,
                    status: this.Status,
                    customer_id: this.CustomerId,
                    payment_method: this.PaymentMethod,
                    bank: this.Bank.value,
                    branch: this.Branch,
                    account_number: this.AccountNumber,
                    account_name: this.AccountName,
                    notes: this.Note,
                    round_up: parseFloat(this.RoundUp),
                    delivery_fee: parseFloat(this.DeliveryFee),
                    admin_fee: parseFloat(this.AdminFee),
                    other_fee: parseFloat(this.OtherFee),
                    total: parseFloat(this.TotalPaymentAmount),
                    transaction_invoice_payment_detail: invoicePaymentDetail
                }

                if (this.SaveType == 'Add'){
                    this.$loading(true);
                    
                    const variables = {
                        data : paymentInvoiceData
                    }

                    paymentInvoiceServices.addQuery(variables).then(res => {
                        this.$loading(false);
                        this.$swal("Info", response.successSave, "success").then(
                            this.$router.push({ path: '/payment-invoice' })
                        );
                    }).catch(error => {
                        this.$loading(false);
                        var errorMessage = globalfunc.errorExtractor(error)
                        this.$swal("Error", errorMessage, "error");
                    })
                }
                else if (this.SaveType == 'Edit'){
                    this.$loading(true);

                    const variables = {
                        id : parseInt(this.Id),
                        data : paymentInvoiceData
                    }
                    
                    paymentInvoiceServices.editQuery(variables).then(res => {
                        this.$loading(false);
                        this.$swal("Info", response.successUpdate, "success")
                            .then(res => {
                                if(res.isConfirmed == true || res.isDismissed == true){
                                    window.location = baseUrl + '/payment-invoice'
                                }
                            });
                    }).catch(error => {
                        this.$loading(false);
                        var errorMessage = globalfunc.errorExtractor(error)
                        this.$swal("Error", errorMessage, "error");
                    })
                }
            }
        },
    } 
}
</script>

<style scoped>
</style>